import * as React from 'react';
import { useState, useEffect } from 'react';

import { Stack, IconButton, Autocomplete, Typography, TextField, styled } from '@mui/material';
import AddCommentIcon from '@mui/icons-material/AddComment';

import Backdrop from '../common/backdrop';
import ErrorDialog from '../common/error-dialog';

import { useTranslation } from 'react-i18next';
import MinistryNotesDialog from './ministry-notes-dialog';
import { replaceObjectInArrayByKeyField } from '../../helpers/replaceObjectInArrayByKeyField';

import { ButtonTextField } from '../ButtonTextField';

const CustomDisableInput = styled(TextField)(() => ({
  '.MuiInputBase-input.Mui-disabled': {
    WebkitTextFillColor: '#000',
    color: '#000',
    paddingLeft: '8px',
    paddingRight: '8px',
    textAlign: 'center',
  },
}));

/**
 * Supported:
 * 
 * sunday_intervals =[
	{
		"from": "08:00",
		"to": "10:30",
		"duration": 30,
		"allowedusers": 2
	},
	{
		"from": "11:00",
		"to": "15:00",
		"duration": 60,
		"allowedusers": 4
	},
	{
		"from": "16:00",
		"to": "19:00",
		"duration": 30,
		"allowedusers": 2
	}
]
 * @param
 * @returns 
 */
function Form(props) {
  const { t } = useTranslation();

  const {
    adminMode,
    dataToDisplay: data,
    uid: currentUserId,
    openedDay: ministryDate,
    availablePeople,
    openedDayOfWeek,
    standParams,
  } = props;

  const dayIntervals = JSON.parse(standParams[openedDayOfWeek.toLowerCase() + '_intervals']);

  const [ministryRecords, setMinistryRecords] = useState([]);

  const [backdropOpen, setBackdropOpen] = useState(false);
  const [error, setError] = useState(null);

  // Ministry Notes Dialog
  const [displayMinistryNotesDialog, setDisplayMinistryNotesDialog] = useState({
    open: false,
  });

  const selectOptions = availablePeople.map(item => {
    const firstLetter = item.lastName[0].toUpperCase();
    return {
      firstLetter,
      label: item.lastName + ' ' + item.firstName,
      id: item.id,
    };
  });

  useEffect(() => {
    let ministryCellsNew = [];

    for (let i = 0; i < dayIntervals.length; i++) {
      let [fromHours, fromMinutes] = dayIntervals[i].from.split(':').map(Number);
      let [toHours, toMinutes] = dayIntervals[i].to.split(':').map(Number);
  
      let currentTime = new Date();
      currentTime.setHours(fromHours, fromMinutes);
  
      let endTime = new Date();
      endTime.setHours(toHours, toMinutes);
  
      const duration = dayIntervals[i].duration ? parseInt(dayIntervals[i].duration) : 60;
      const allowedusers = dayIntervals[i].allowedusers
        ? parseInt(dayIntervals[i].allowedusers)
        : 2;
      while (currentTime <= endTime) {
        let hours = currentTime.getHours();
        let minutes = currentTime.getMinutes();

        const ministryTime = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;

        let ministryId = null,
          ministryUsers = [];

        // Check if there is a matching entry in dataStands for the current time
        const matchingMinistry = data.find(ministry => {
          const ministryStartAt = new Date(ministry.start_at);
          return (
            ministryStartAt.getHours() === currentTime.getHours() &&
            ministryStartAt.getMinutes() === currentTime.getMinutes()
          );
        });

        if (matchingMinistry) {
          ministryId = matchingMinistry.id;

          for (let j = 0; j < allowedusers; j++) {
            ministryUsers[j] = availablePeople.find(
              user => user.id === matchingMinistry[`user${j + 1}_id`]
            );
          }
        }

        ministryCellsNew.push({
          ministryId,
          ministryDate,
          ministryTime,
          ministryUsers,
          duration,
          allowedusers,
        });

        currentTime.setMinutes(currentTime.getMinutes() + Number(duration));
      }
    }

    setMinistryRecords(ministryCellsNew);
  }, [data]);

  const updateDom = row => {
    let updatedMinistryRecords = replaceObjectInArrayByKeyField(
      ministryRecords,
      row,
      'ministryTime'
    );

    setMinistryRecords(updatedMinistryRecords);
  };

  const handleAdminUpdate = async (oldMinistryRecord, newId, user0BasedIdx) => {
    setBackdropOpen(true);

    if (!oldMinistryRecord.ministryId) {
      // if no recorods for this time
      try {
        const url = `${process.env.REACT_APP_API_URL}/ministry`;

        let body = {
          start_at: convertMinistryDateTime2start_at(oldMinistryRecord),
          stand_id: standParams.id,
          duration: oldMinistryRecord.duration,
        };

        for (let j = 0; j < oldMinistryRecord.allowedusers; j++) {
          body[`user${j + 1}_id`] = null;
        }
        body[`user${user0BasedIdx + 1}_id`] = newId;

        const headers = {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.jwt}`,
          },
          body: JSON.stringify(body),
        };

        await fetch(url, headers)
          .then(response => {
            if (response.status === 401) {
              window.location = '/sign-in';
            } else if (response.ok) {
              return response.json();
            } else if (response.status === 409) {
              console.error('Duplicate entry. Response=', response);

              props.alert();
            }
          })
          .then(data => {
            oldMinistryRecord.ministryId = data.insertedId;
            oldMinistryRecord.ministryUsers = [];

            for (let j = 0; j < oldMinistryRecord.allowedusers; j++) {
              oldMinistryRecord.ministryUsers[j] = null;
            }
            oldMinistryRecord.ministryUsers[user0BasedIdx] = availablePeople.find(
              user => user.id === newId
            );

            updateDom(oldMinistryRecord);
            body.id = data.insertedId;

            props.updateDataFromForm(body);

            setBackdropOpen(false);
          })
          .catch(err => {
            props.setError(t('Sorry! Failed to connect server =('));
          });
      } catch (error) {
        console.error(error);
        setError(error);
      }
    } else {
      try {
        const url = `${process.env.REACT_APP_API_URL}/ministry/${oldMinistryRecord.ministryId}`;

        let body = {
          start_at: convertMinistryDateTime2start_at(oldMinistryRecord),
          stand_id: standParams.id,
          duration: oldMinistryRecord.duration,
        };

        if (oldMinistryRecord.ministryUsers[user0BasedIdx]) {
          if (oldMinistryRecord.ministryUsers[user0BasedIdx].id > 0) {
            body[`old_user${user0BasedIdx + 1}_id`] =
              oldMinistryRecord.ministryUsers[user0BasedIdx].id;
            body[`user${user0BasedIdx + 1}_id`] = newId;
          } else {
            console.error(
              'This should never happen. Only Admin can change other user',
              user0BasedIdx,
              oldMinistryRecord
            );
          }
        } else {
          body[`user${user0BasedIdx + 1}_id`] = newId;
        }

        const headers = {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.jwt}`,
          },
          body: JSON.stringify(body),
        };

        await fetch(url, headers)
          .then(response => {
            if (response.status === 401) {
              window.location = '/sign-in';
            } else if (response.ok) {
              return response.json();
            } else if (response.status === 409) {
              console.error('#238 Duplicate entry. Respons', response);

              props.alert();
            }
          })
          .then(data => {
            let ministryRecord = convertResponse2ministryRecord(
              data,
              oldMinistryRecord.allowedusers
            );

            updateDom(ministryRecord);

            props.updateDataFromForm(data);

            setBackdropOpen(false);
          })
          .catch(err => {
            props.setError(t('Sorry! Failed to connect server =('));
          });
      } catch (error) {
        console.error(error);
        setError(error);
      }
    }
  };

  const handleUpdate = async (oldMinistryRecord, user0BasedIdx) => {
    setBackdropOpen(true);

    if (!oldMinistryRecord.ministryId) {
      // if no recorods for this time
      try {
        const url = `${process.env.REACT_APP_API_URL}/ministry`;

        let body = {
          start_at: convertMinistryDateTime2start_at(oldMinistryRecord),
          stand_id: standParams.id,
          duration: oldMinistryRecord.duration,
        };

        for (let j = 0; j < oldMinistryRecord.allowedusers; j++) {
          body[`user${j + 1}_id`] = null;
        }
        body[`user${user0BasedIdx + 1}_id`] = currentUserId;

        const headers = {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.jwt}`,
          },
          body: JSON.stringify(body),
        };

        await fetch(url, headers)
          .then(response => {
            if (response.status === 401) {
              window.location = '/sign-in';
            } else if (response.ok) {
              return response.json();
            } else if (response.status === 409) {
              console.error('Duplicate entry. Response=', response);
              props.alert();
            }
          })
          .then(data => {
            oldMinistryRecord.ministryId = data.insertedId;
            oldMinistryRecord.ministryUsers = [];

            for (let j = 0; j < oldMinistryRecord.allowedusers; j++) {
              oldMinistryRecord.ministryUsers[j] = null;
            }
            oldMinistryRecord.ministryUsers[user0BasedIdx] = availablePeople.find(
              user => user.id === currentUserId
            );

            updateDom(oldMinistryRecord);
            body.id = data.insertedId;

            props.updateDataFromForm(body);

            setBackdropOpen(false);
          })
          .catch(err => {
            props.setError(t('Sorry! Failed to connect server =('));
          });
      } catch (error) {
        console.error(error);
        setError(error);
      }
    } else {
      try {
        const url = `${process.env.REACT_APP_API_URL}/ministry/${oldMinistryRecord.ministryId}`;

        let body = {
          start_at: convertMinistryDateTime2start_at(oldMinistryRecord),
          stand_id: standParams.id,
          duration: oldMinistryRecord.duration,
        };

        if (oldMinistryRecord.ministryUsers[user0BasedIdx]) {
          if (oldMinistryRecord.ministryUsers[user0BasedIdx].id === currentUserId) {
            body[`old_user${user0BasedIdx + 1}_id`] =
              oldMinistryRecord.ministryUsers[user0BasedIdx].id;
            body[`user${user0BasedIdx + 1}_id`] = 0;
          } else {
            console.error(
              'This should never happen. Only Admin can change not own user',
              user0BasedIdx,
              oldMinistryRecord
            );
          }
        } else {
          body[`user${user0BasedIdx + 1}_id`] = currentUserId;
        }

        const headers = {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.jwt}`,
          },
          body: JSON.stringify(body),
        };

        await fetch(url, headers)
          .then(response => {
            if (response.status === 401) {
              window.location = '/sign-in';
            } else if (response.ok) {
              return response.json();
            } else if (response.status === 409) {
              console.error('#238 Duplicate entry. Respons', response);

              props.alert();
            }
          })
          .then(data => {
            let ministryRecord = convertResponse2ministryRecord(
              data,
              oldMinistryRecord.allowedusers
            );

            updateDom(ministryRecord);

            props.updateDataFromForm(data);

            setBackdropOpen(false);
          })
          .catch(err => {
            props.setError(t('Sorry! Failed to connect server =('));
          });
      } catch (error) {
        console.error(error);
        setError(error);
      }
    }
  };

  function convertMinistryDateTime2start_at(ministryRecord) {
    return `${ministryRecord.ministryDate} ${ministryRecord.ministryTime}:00`;
  }

  function convertResponse2ministryRecord(row, allowedusers) {
    let ministryRecord = {};

    ministryRecord.ministryId = row.id;
    ministryRecord.standId = row.stand_id;
    ministryRecord.notes = row.notes;
    ministryRecord.duration = row.duration;
    ministryRecord['allowedusers'] = allowedusers;

    const dateTimeParts = row.start_at.split('T');
    ministryRecord.ministryDate = dateTimeParts[0];
    ministryRecord.ministryTime = dateTimeParts[1];

    ministryRecord.ministryUsers = [];
    for (let j = 0; j < allowedusers; j++) {
      const userId = row[`user${j + 1}_id`];
      const userRecord = availablePeople.find(user => user.id === userId);
      ministryRecord.ministryUsers[j] = userRecord ? userRecord : null;
    }
    return ministryRecord;
  }

  const handleClick = (cell, cellNum) => {
    handleUpdate(cell, cellNum);
  };

  const handleAddOrUpdateMinistryNotesClick = (cell) => {
    setDisplayMinistryNotesDialog({ open: true, cell: cell });
  };

  const splitArrayInHalf = array => {
    const middleIndex = Math.floor(array.length / 2);

    const firstHalf = array.slice(0, middleIndex);
    const secondHalf = array.slice(middleIndex);

    return [firstHalf, secondHalf];
  };

  return (
    <React.Fragment>
      <Stack my={1}>
        <Backdrop open={backdropOpen} />
        <ErrorDialog open={Boolean(error)} text={error} />
        <MinistryNotesDialog
          params={displayMinistryNotesDialog}
          func={setDisplayMinistryNotesDialog}
        />
        <Stack gap={2}>
          {ministryRecords.map((ministry, i) => {
            const disabled =
              new Date(convertMinistryDateTime2start_at(ministry)) <
              new Date(
                new Date().setHours(new Date().getHours() - standParams.cancelBeforHours)
              ).setDate(new Date().getDate() - standParams.cancelBeforDays);

            const disableNotes = !(
              disabled && ministry.ministryUsers.some(user => user && user.id !== null)
            );

            const ministryUsersLength = ministry.ministryUsers.length;
            const emptyMinistryUsers = Array(ministry.allowedusers / 2);

            const [ministryUsersFirstHalf, ministryUsersSecondHalf] = splitArrayInHalf(
              ministry.ministryUsers
            );

            return (
              <React.Fragment key={'ReactFragment' + ministry.id + i}>
                <Stack flexDirection="row" flexWrap="wrap" alignItems="center">
                  <Typography
                    mx={2}
                    color={t => (disabled ? t.palette.text.disabled : t.palette.text.primary)}
                  >
                    {ministry.ministryTime}
                  </Typography>

                  <Stack gap={1} flex={1} direction="row">
                    <Stack flex={1} gap={1}>
                      {ministryUsersFirstHalf.map((user, index) => (
                        <React.Fragment key={'User' + ministry.ministryTime + index}>
                          {adminMode ? (
                            <Autocomplete
                              disablePortal
                              autoSelect
                              clearOnEscape
                              id="combo-box-publisher-2"
                              value={
                                user && user.id
                                  ? selectOptions.find(option => {
                                      return option.id === user.id;
                                    })
                                  : null
                              }
                              options={selectOptions.sort(
                                (a, b) => -b.firstLetter.localeCompare(a.firstLetter)
                              )}
                              groupBy={option => option.firstLetter}
                              onChange={(event, newValue) => {
                                handleAdminUpdate(ministry, newValue ? newValue.id : 0, index);
                              }}
                              renderInput={params => (
                                <TextField
                                  fullWidth
                                  {...params}
                                  label={`${t('Publisher')} ${index + 1}`}
                                />
                              )}
                            />
                          ) : user && user.id === currentUserId ? (
                            <ButtonTextField
                              color={user.id === currentUserId ? 'error' : 'primary'}
                              value={
                                user.id === currentUserId
                                  ? `${user.lastName} ${user.firstName}`
                                  : t('Check In')
                              }
                              disabled={disabled}
                              onClick={() => handleClick(ministry, index)}
                            />
                          ) : user ? (
                            <CustomDisableInput
                              variant="outlined"
                              value={`${user.lastName} ${user.firstName}`}
                              size="small"
                              disabled
                              fullWidth
                            />
                          ) : (
                            <ButtonTextField
                              color="primary"
                              value={t('Check In')}
                              disabled={disabled}
                              onClick={() => handleClick(ministry, index)}
                            />
                          )}
                        </React.Fragment>
                      ))}

                      {ministryUsersLength < ministry.allowedusers &&
                        [...emptyMinistryUsers].map((_, index) => (
                          <React.Fragment key={'Empty' + ministry.ministryTime + index}>
                            {adminMode ? (
                              <Autocomplete
                                disablePortal
                                autoSelect
                                clearOnEscape
                                id="combo-box-publisher-2"
                                value={null}
                                options={selectOptions.sort(
                                  (a, b) => -b.firstLetter.localeCompare(a.firstLetter)
                                )}
                                groupBy={option => option.firstLetter}
                                onChange={(event, newValue) => {
                                  handleAdminUpdate(ministry, newValue.id, index);
                                }}
                                renderInput={params => (
                                  <TextField
                                    fullWidth
                                    {...params}
                                    label={t('Publisher') + ' ' + (index + 1)}
                                  />
                                )}
                              />
                            ) : (
                              <ButtonTextField
                                color="primary"
                                value={t('Check In')}
                                disabled={disabled}
                                onClick={() => handleClick(ministry, index)}
                              />
                            )}
                          </React.Fragment>
                        ))}
                    </Stack>
                    <Stack flex={1} gap={1}>
                      {ministryUsersSecondHalf.map((user, index) => (
                        <React.Fragment
                          key={'User' + ministry.ministryTime + index + ministry.allowedusers / 2}
                        >
                          {adminMode ? (
                            <Autocomplete
                              disablePortal
                              autoSelect
                              clearOnEscape
                              id="combo-box-publisher-2"
                              value={
                                user && user.id
                                  ? selectOptions.find(option => {
                                      return option.id === user.id;
                                    })
                                  : null
                              }
                              options={selectOptions.sort(
                                (a, b) => -b.firstLetter.localeCompare(a.firstLetter)
                              )}
                              groupBy={option => option.firstLetter}
                              onChange={(event, newValue) => {
                                handleAdminUpdate(
                                  ministry,
                                  newValue ? newValue.id : 0,
                                  index + ministry.allowedusers / 2
                                );
                              }}
                              renderInput={params => (
                                <TextField
                                  fullWidth
                                  {...params}
                                  label={`${t('Publisher')} ${
                                    index + ministry.allowedusers / 2 + 1
                                  }`}
                                />
                              )}
                            />
                          ) : user && user.id === currentUserId ? (
                            <ButtonTextField
                              color={user.id === currentUserId ? 'error' : 'primary'}
                              value={
                                user.id === currentUserId
                                  ? `${user.lastName} ${user.firstName}`
                                  : t('Check In')
                              }
                              disabled={disabled}
                              onClick={() =>
                                handleClick(ministry, index + ministry.allowedusers / 2)
                              }
                            />
                          ) : user ? (
                            <CustomDisableInput
                              variant="outlined"
                              value={`${user.lastName} ${user.firstName}`}
                              size="small"
                              disabled
                              fullWidth
                            />
                          ) : (
                            <ButtonTextField
                              color="primary"
                              value={t('Check In')}
                              disabled={disabled}
                              onClick={() =>
                                handleClick(ministry, index + ministry.allowedusers / 2)
                              }
                            />
                          )}
                        </React.Fragment>
                      ))}

                      {ministryUsersLength < ministry.allowedusers &&
                        [...emptyMinistryUsers].map((_, index) => (
                          <React.Fragment
                            key={
                              'Empty' + ministry.ministryTime + index + ministry.allowedusers / 2
                            }
                          >
                            {adminMode ? (
                              <Autocomplete
                                disablePortal
                                autoSelect
                                clearOnEscape
                                id="combo-box-publisher-2"
                                value={null}
                                options={selectOptions.sort(
                                  (a, b) => -b.firstLetter.localeCompare(a.firstLetter)
                                )}
                                groupBy={option => option.firstLetter}
                                onChange={(event, newValue) => {
                                  handleAdminUpdate(
                                    ministry,
                                    newValue.id,
                                    index + ministry.allowedusers / 2
                                  );
                                }}
                                renderInput={params => (
                                  <TextField
                                    fullWidth
                                    {...params}
                                    label={
                                      t('Publisher') + ' ' + (index + ministry.allowedusers / 2 + 1)
                                    }
                                  />
                                )}
                              />
                            ) : (
                              <ButtonTextField
                                color="primary"
                                value={t('Check In')}
                                disabled={disabled}
                                onClick={() =>
                                  handleClick(ministry, index + ministry.allowedusers / 2)
                                }
                              />
                            )}
                          </React.Fragment>
                        ))}
                    </Stack>
                  </Stack>

                  <IconButton
                    size="large"
                    aria-haspopup="true"
                    onClick={() => handleAddOrUpdateMinistryNotesClick(ministry)}
                    color="secondary"
                    disabled={disableNotes}
                    style={{ padding: '0 8px' }}
                  >
                    <AddCommentIcon style={{ fontSize: '32px' }} />
                  </IconButton>
                </Stack>
              </React.Fragment>
            );
          })}
        </Stack>
      </Stack>
    </React.Fragment>
  );
}

export default Form;
