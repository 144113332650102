import { Scalars, UserPermissions } from 'types';

type JWTData = {
  id?: Scalars['Int'];
  permissions?: UserPermissions[];
  isAllowed4Stand?: Scalars['Switch'];
};

export const getJwtData = (): JWTData => {
  // console.log('-------------getJwtData', localStorage)
  if (!localStorage) {
    // console.log('-------------localStorage is not available')
    return {};
  }
  if (!localStorage.jwt || localStorage.jwt === 'undefined') {
    // console.log('--------------JWT token is not found in local storage')
    return {};
  }

  const base64Url = localStorage.jwt.split('.')[1];
  // console.log('base64Url', base64Url)
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join('')
  );

  return JSON.parse(jsonPayload);
};
