import * as React from 'react';
import { useState, useEffect } from 'react';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { styled } from '@mui/material/styles';

import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';

import { IMaskInput } from 'react-imask';

import ErrorDialog from '../common/error-dialog';
import Loading from '../common/loading';
import BackDrop from '../common/backdrop';
import Snackbar from '../common/snackbar';
import { parseJwt } from '../common/parseJwt';

import { useTranslation } from 'react-i18next';

const TextMaskCustom = React.forwardRef(function TextMaskCustom(props, ref) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      // mask="(#00) 000-0000"
      definitions={{
        '#': /[0-9]/,
      }}
      inputRef={ref}
      onAccept={value => onChange({ target: { firstName: props.firstName, value } })}
      overwrite
    />
  );
});

const CustomDisableInput = styled(TextField)(() => ({
  '.MuiInputBase-input.Mui-disabled': {
    WebkitTextFillColor: '#000',
    color: '#000',
  },
}));

function getJsonFromUrl(url) {
  if (!url) url = window.location.search;
  var query = url.substr(1);
  var result = {};
  query.split('&').forEach(function (part) {
    var item = part.split('=');
    result[item[0]] = decodeURIComponent(item[1]);
  });
  return result;
}

let oldData = [];
let guid;

function Profile() {
  const { t } = useTranslation(); // i18n

  const jwtData = parseJwt(localStorage.jwt);
  const canEdit = jwtData.permissions.find(role => role === 'users_edit') !== undefined;

  const [email, changeEmail] = useState(null);
  const [firstName, changeName] = useState(null);
  const [lastName, changeSurname] = useState(null);
  const [phoneNumbers, changePhoneNumbers] = useState(null);
  const [password, changePassword] = useState('');
  const [confirmPassword, changeConfirmPassword] = useState('');
  const [isAllowed4Stand, setIsAllowed4Stand] = useState(null);
  const [isConfirmed, setIsConfirmed] = useState(null);

  const [firstNameValidationErr, setFirstNameValidationErr] = useState(null);
  const [lastNameValidationErr, setLastNameValidationErr] = useState(null);
  const [pnValidationErr, setPNValidationErr] = useState(null);
  const [emailValidationErr, setEmailValidationErr] = useState(null);
  const [passwordValidationErr, setPasswordValidationErr] = useState(null);
  const [confirmPasswordValidationErr, setConfirmPasswordValidationErr] = useState(null);

  const [dataEdited, setDataEdited] = useState(false);
  const [backdropOpen, setBackdropOpen] = useState(false);
  const [snackbar, setSnackbar] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState(null);

  const handleEmailChange = event => {
    if (emailValidationErr) setEmailValidationErr(null);
    changeEmail(event.target.value);
  };
  const handleNameChange = event => {
    if (firstNameValidationErr) setFirstNameValidationErr(null);
    changeName(event.target.value);
  };
  const handleSurnameChange = event => {
    if (lastNameValidationErr) setLastNameValidationErr(null);
    changeSurname(event.target.value);
  };
  const handlePasswordChange = event => {
    if (passwordValidationErr) setPasswordValidationErr(null);
    changePassword(event.target.value);
  };
  const handleConfirmPasswordChange = event => {
    if (confirmPasswordValidationErr) setConfirmPasswordValidationErr(null);
    changeConfirmPassword(event.target.value);
  };
  const handleSetIsAllowed4Stand = () => setIsAllowed4Stand(!isAllowed4Stand);
  const handleSetIsComfirned = () => setIsConfirmed(!isConfirmed);
  const handlePNChange = (event, idx) => {
    const newPNs = phoneNumbers.map((pn, i) => {
      if (idx === i) pn = event.target.value;

      return pn;
    });

    changePhoneNumbers(newPNs);
  };

  const editPNs = i => {
    let newPNs = phoneNumbers;
    if (i === 0) newPNs.push('');
    else newPNs.splice(i, 1);

    changePhoneNumbers([...newPNs]);
  };

  const getData = async uid => {
    try {
      const url = `${process.env.REACT_APP_API_URL}/users/${uid}`;
      const headers = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.jwt}`,
        },
      };

      await fetch(url, headers)
        .then(response => {
          if (response.status === 401) {
            window.location = '/sign-in';
          } else if (response.ok) {
            return response.json();
          } else {
            console.log(response);
            setError(`${response.status} ${response.statusText}`);
          }
        })
        .then(data => {
          const phones = data.phones ? data.phones.split(';') : [''];

          oldData = [
            data.email,
            data.firstName,
            data.lastName,
            password,
            [...phones],
            data.isAllowed4Stand === 1,
            data.active === 1,
          ];

          setData(data);

          changeEmail(data.email);
          changeName(data.firstName);
          changeSurname(data.lastName);
          setIsAllowed4Stand(data.isAllowed4Stand === 1);
          setIsConfirmed(data.active === 1);

          guid = data.guid;

          changePhoneNumbers(phones);
        })
        .catch(err => {
          console.log(err);
          setError(t('Sorry! Failed to connect server =('));
        });
    } catch (error) {
      setError(error);
    }
  };

  const confirm = async () => {
    setBackdropOpen(true);

    let errors = {
      firstName: firstNameValidationErr,
      lastName: lastNameValidationErr,
      phoneNumber: pnValidationErr,
      email: emailValidationErr,
      password: passwordValidationErr,
      confirmPassword: confirmPasswordValidationErr,
    };

    if (firstName.length === 0) errors.firstName = t('Please provide your first firstName.');
    else if (!/^.{2,30}$/.test(firstName)) errors.firstName = t('Allowed 2-30 symbols');
    if (lastName.length === 0) errors.lastName = t('Please provide your last firstName.');
    else if (!/^.{2,30}$/.test(lastName)) errors.lastName = t('Allowed 2-30 symbols');
    if (email.length === 0) errors.email = t('Please provide your email');
    else if (
      !/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i.test(
        email
      )
    )
      errors.email = t('Please, provide valid email');
    if (password.length > 0) {
      console.log('password:', password, password.length);
      if (!/^(?=.*[А-ЯA-Z])(?=.*[а-яa-z])(?=.*\d)[A-Za-z\d@$!%*#?&]{8,}$/.test(password))
        errors.password = t('PasswordMinimumParams');

      if (confirmPassword !== password) errors.confirmPassword = t('Passwords do not match.');
      else errors.confirmPassword = null;
    } else {
      errors.password = null;
      errors.confirmPassword = null;
    }

    if (
      errors.firstName ||
      errors.lastName ||
      errors.phoneNumber ||
      errors.email ||
      errors.password ||
      errors.confirmPassword
    ) {
      console.log('errors found:', errors);
      setBackdropOpen(false);

      setFirstNameValidationErr(errors.firstName);
      setLastNameValidationErr(errors.lastName);
      setPNValidationErr(errors.phoneNumber);
      setEmailValidationErr(errors.email);
      setPasswordValidationErr(errors.password);
      setConfirmPasswordValidationErr(errors.confirmPassword);

      return;
    }

    // firstName lastName phoneNumbers
    try {
      const url = `${process.env.REACT_APP_API_URL}/users/${getJsonFromUrl().id}`;
      const body = {
        email: email,
        firstName: firstName,
        lastName: lastName,
        password: password,
        phones: phoneNumbers.join(';'),
        isAllowed4Stand: isAllowed4Stand,
        active: isConfirmed,
      };
      const request = {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.jwt}`,
        },
        body: JSON.stringify(body),
      };

      await fetch(url, request)
        .then(response => {
          if (response.status === 401) {
            window.location = '/sign-in';
          } else if (response.ok) {
            oldData = [
              email,
              firstName,
              lastName,
              password,
              [...phoneNumbers],
              isAllowed4Stand,
              isConfirmed,
            ];

            setDataEdited(false);
            setBackdropOpen(false);
            setSnackbar('Successfuly updated data');
          }
        })
        .catch(err => {
          console.log(err);
          setError(t('Sorry! Failed to connect server =('));
        });
    } catch (error) {
      setError(error);
    }
  };

  const goBack = () => window.history.back();
  const hideSnackbar = () => setSnackbar(false);

  const copyGuid = () => {
    if (!guid) {
      alert(t('Guid undefined'));
      window.location = '/sign-in';
    }

    navigator.clipboard
      .writeText(`${process.env.REACT_APP_URL}/guid?id=${guid}`) // TODO unhardcode link
      .then(() => {
        setSnackbar('Successfuly copied');
      })
      .catch(err => {
        setSnackbar(`Failed to copy`);
      });
  };

  useEffect(() => {
    getData(getJsonFromUrl().id);
  }, []);

  useEffect(() => {
    const isEqual =
      JSON.stringify(oldData) ===
      JSON.stringify([
        email,
        firstName,
        lastName,
        password,
        phoneNumbers,
        isAllowed4Stand,
        isConfirmed,
      ]);
    if (!isEqual) {
      setDataEdited(true);
    } else {
      setDataEdited(false);
    }
  }, [email, firstName, lastName, password, phoneNumbers, isAllowed4Stand, isConfirmed]);

  let visibleGuid = false;
  const visibleForRoles = ['stand_admin', 'users_edit', 'users_view'];
  jwtData.permissions.forEach(permission => {
    if (visibleForRoles.indexOf(permission) !== -1) visibleGuid = true;
  });

  if (error) return <ErrorDialog open={Boolean(error)} text={error} />;
  else if (!data) return <Loading />;
  return (
    <Grid container style={{ width: '100%', padding: '32px', margin: '0px' }}>
      <BackDrop open={backdropOpen} />
      <Snackbar
        type={snackbar ? (snackbar.startsWith('Success') ? 'success' : 'error') : 'error'}
        msg={t(snackbar ? snackbar : '')}
        open={Boolean(snackbar)}
        hideSnackbar={() => hideSnackbar()}
      />

      <Grid item xs={0} md={3} lg={4}></Grid>

      <Grid container item xs={12} md={6} lg={4}>
        <Paper elevation={5} style={{ width: '100%', padding: '12px', backgroundColor: '#FAFAFA' }}>
          <Grid item xs={12}>
            <Typography variant="h3" gutterBottom component="div" align="center">
              <AccountCircleIcon style={{ fontSize: '64px' }} />
            </Typography>
          </Grid>

          <Grid container spacing={3} item xs={12}>
            <Grid item xs={12}>
              <CustomDisableInput
                label={t('Email Address')}
                variant="outlined"
                style={{ width: '100%' }}
                error={Boolean(emailValidationErr)}
                helperText={emailValidationErr}
                disabled={!canEdit}
                value={email}
                onChange={handleEmailChange}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <CustomDisableInput
                label={t('First Name')}
                variant="outlined"
                style={{ width: '100%' }}
                error={Boolean(firstNameValidationErr)}
                helperText={firstNameValidationErr}
                disabled={!canEdit}
                value={firstName}
                onChange={handleNameChange}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <CustomDisableInput
                label={t('Last Name')}
                variant="outlined"
                style={{ width: '100%' }}
                error={Boolean(lastNameValidationErr)}
                helperText={lastNameValidationErr}
                disabled={!canEdit}
                value={lastName}
                onChange={handleSurnameChange}
              />
            </Grid>
          </Grid>

          <Grid container spacing={3} item xs={12} style={{ paddingTop: '24px' }}>
            {phoneNumbers.map((pn, i) => {
              return (
                <Grid item xs={12} style={{ display: 'flex' }} key={i}>
                  <FormControl style={{ width: '100%' }}>
                    <CustomDisableInput
                      label={t('Phone Number')}
                      disabled={!canEdit}
                      value={pn}
                      onChange={e => handlePNChange(e, i)}
                    />
                  </FormControl>

                  <IconButton
                    size="small"
                    onClick={() => editPNs(i)}
                    style={{ display: canEdit ? 'block' : 'none' }}
                  >
                    {i === 0 ? <AddCircleIcon /> : <RemoveCircleIcon />}
                  </IconButton>
                </Grid>
              );
            })}
          </Grid>

          <Grid item xs={12} style={{ paddingTop: '24px', display: canEdit ? 'block' : 'none' }}>
            <TextField
              label={t('New Password')}
              variant="outlined"
              style={{ width: '100%' }}
              type="password"
              error={Boolean(passwordValidationErr)}
              helperText={passwordValidationErr}
              value={password}
              onChange={handlePasswordChange}
            />
          </Grid>

          <Grid
            item
            xs={12}
            style={{
              paddingTop: '24px',
              display: canEdit && password.length !== 0 ? 'block' : 'none',
            }}
          >
            <TextField
              label={t('Confirm Password')}
              variant="outlined"
              style={{ width: '100%' }}
              type="password"
              error={Boolean(confirmPasswordValidationErr)}
              helperText={confirmPasswordValidationErr}
              value={confirmPassword}
              onChange={handleConfirmPasswordChange}
            />
          </Grid>

          <Grid
            item
            xs={12}
            style={{
              paddingTop: '12px',
              display: visibleGuid ? 'block' : 'none',
            }}
          >
            <Typography gutterBottom component="div" align="left">
              <IconButton size="small" onClick={copyGuid}>
                <ContentCopyIcon />
              </IconButton>
              &nbsp;{t('Copy guid')}
            </Typography>
          </Grid>

          <Grid
            container
            spacing={3}
            item
            xs={12}
            style={{
              paddingLeft: '24px',
              paddingTop: '24px',
              display: canEdit ? 'block' : 'none',
            }}
          >
            <FormControlLabel
              control={<Checkbox checked={isAllowed4Stand} onChange={handleSetIsAllowed4Stand} />}
              label={t('Is Allowed for Stand')}
            />
            <FormControlLabel
              control={<Checkbox checked={isConfirmed} onChange={handleSetIsComfirned} />}
              label={t('Is comfirmed user')}
            />
          </Grid>

          <Grid container spacing={3} item xs={12} style={{ paddingTop: '12px' }}>
            <Grid item xs={0} md={6} lg={8} style={{ display: canEdit ? 'none' : 'block' }}></Grid>

            <Grid item xs={12} md={6}>
              <Button variant="contained" style={{ width: '100%' }} onClick={goBack}>
                {t('Back')}
              </Button>
            </Grid>

            <Grid item xs={12} md={6} style={{ display: canEdit ? 'block' : 'none' }}>
              <Button
                variant="contained"
                style={{ width: '100%' }}
                onClick={confirm}
                disabled={!dataEdited}
              >
                {t('Confirm')}
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Grid>

      <Grid item xs={0} md={3} lg={4}></Grid>
    </Grid>
  );
}

export default Profile;
