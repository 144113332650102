import * as React from 'react';
import { useState, useEffect } from 'react';

import MUIDataTable from 'mui-datatables';

import ErrorDialog from '../common/error-dialog';
import Loading from '../common/loading';
import { parseJwt } from '../common/parseJwt';

import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';

import { useTranslation } from 'react-i18next';

const CustomToolbar = () => {
  const { t } = useTranslation(); // i18n
  return (
    <>
      <Tooltip title="AddUser" arrow>
        <Button onClick={() => (window.location = '/add-user')}>{t('AddUser')}</Button>
      </Tooltip>
    </>
  );
};

let addIsAllowedForStandCol;
let columns;
function Users() {
  const { t } = useTranslation(); // i18n

  const [error, setError] = useState(null);
  const [data, setData] = useState(null);

  let jwtData = parseJwt(localStorage.jwt);

  if (jwtData === null) window.location = '/sign-in';
  if (addIsAllowedForStandCol === undefined)
    addIsAllowedForStandCol =
      jwtData.permissions.find(role => role === 'stand_admin') !== undefined;
  if (!columns)
    columns = [
      {
        name: 'id',
        label: 'id',
        options: {
          filter: false,
          sort: true,
          display: false,
        },
      },
      {
        name: 'firstName',
        label: t('First Name'),
        options: {
          filter: false,
          sort: true,
        },
      },
      {
        name: 'lastName',
        label: t('Last Name'),
        options: {
          filter: false,
          sort: true,
        },
      },
      {
        name: 'email',
        label: t('Email'),
        options: {
          filter: false,
          sort: true,
          display: false,
        },
      },
      {
        name: 'phones',
        label: t('Phone Number'),
        options: {
          filter: false,
          sort: true,
        },
      },
    ];
  if (addIsAllowedForStandCol) {
    columns.push({
      name: 'isAllowed4Stand',
      label: t('Is Allowed for Stand'),
      options: {
        filter: true,
        sort: true,
        display: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return value === 1 ? t('yes') : t('no');
        },
      },
    });
    addIsAllowedForStandCol = false;
  }

  const getPublishers = async () => {
    try {
      const url = `${process.env.REACT_APP_API_URL}/users`;
      const headers = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.jwt}`,
        },
      };

      await fetch(url, headers)
        .then(response => {
          if (response.status === 401) {
            window.location = '/sign-in';
          } else if (response.ok) {
            return response.json();
          } else {
            setError(`${response.status} ${response.statusText}`);
          }
        })
        .then(data => {
          setData(data);
        })
        .catch(err => {
          setError(t('Sorry! Failed to connect server =('));
        });
    } catch (error) {
      setError(error);
    }
  };

  const rowClick = rowData => {
    window.location = '/user?id=' + rowData[0];
  };

  useEffect(() => {
    getPublishers();
  }, []);

  const options = {
    filterType: 'checkbox',
    selectableRows: false,
    onRowClick: rowData => rowClick(rowData),
    customToolbar: () => {
      if (jwtData.permissions.indexOf('users_edit') === -1) return;
      return <CustomToolbar />;
    },
  };

  if (error) return <ErrorDialog open={Boolean(error)} text={error} />;
  else if (!data) return <Loading />;
  return <MUIDataTable title={t('Users')} data={data} columns={columns} options={options} />;
}

export default Users;
