import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import { useTranslation } from 'react-i18next';
import ErrorDialog from '../common/error-dialog';

export default function MinistryNotesDialog(props) {
  const { t } = useTranslation(); // i18n

  const [publications, setPublications] = React.useState(0);
  const handlePublicationsChange = event => {
    // console.log("Publications: ", event.target.value, typeof event.target.value)
    setPublications(Number(event.target.value));
  };
  const [talks, setTalks] = React.useState(0);
  const handleTalksChange = event => {
    // console.log("talks: ", event.target.value, typeof event.target.value)
    setTalks(Number(event.target.value));
  };
  const [notes, setNotes] = React.useState('');
  const handleNotesChange = event => {
    // console.log("Notes: ", event.target.value)
    setNotes(event.target.value);
  };
  const [error, setError] = React.useState(null);

  React.useEffect(() => {

    if (props.params.open) {
      // console.log("useEffect.Open! ", props.params.cell);

      try {
        const url = `${process.env.REACT_APP_API_URL}/ministryNotes/${props.params.cell.ministryId}`;
        const headers = {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.jwt}`,
          },
        };

        fetch(url, headers)
          .then(response => {
            // console.log("response: ", response);
            if (response.status === 401) window.location = '/sign-in';
            else if (response.ok) return response.json();
            else alert(`${response.status} ${response.statusText}`);
          })
          .then(data => {

            if (data && data.notes) {
              let mNotesFromDB;
              try {

                if (typeof data.notes === 'string') {
                  try {
                    mNotesFromDB = JSON.parse(data.notes)
                    mNotesFromDB.notes = fromBase64(mNotesFromDB.notes);
                  } catch (err) {
                    console.error('E#90: ', err);
                    alert(err);
                    return;
                  }
                          
                } else {
                  mNotesFromDB = data.notes;
                }
                setTalks(mNotesFromDB.talks ? mNotesFromDB.talks : 0);
                setPublications(mNotesFromDB.publications ? mNotesFromDB.publications : 0);
                setNotes(mNotesFromDB.notes ? mNotesFromDB.notes : '');
              } catch (error) {
                console.error('e#69 error ', error);
                console.error(error);
                alert(error);
              }
            } else {
              setTalks(0);
              setPublications(0);
              setNotes('');
            }
          })
          .catch(err => {
            console.error('E#112: ', err);
            alert(t('Sorry! Failed to connect server =('));
          });
      } catch (error) {
        console.error('E#82: ', error);
        alert(error);
      }
    }
  }, [props.params.open]);

  function toBase64(str) {
    if (typeof str !== 'string') {
      console.warn('Input must be a string');
      return '';
    }
  
    const trimmedStr = str.trim();
    if (trimmedStr === '') {
      console.warn('Input string is empty or consists only of non-visible symbols');
      return '';
    }
  
    try {
      const encoder = new TextEncoder();
      const data = encoder.encode(trimmedStr);
      return btoa(String.fromCharCode(...new Uint8Array(data)));
    } catch (error) {
      console.error('Failed to encode string to Base64:', error);
      return '';
    }
  }

  function fromBase64(base64) {
    const binary = atob(base64);
    const bytes = new Uint8Array(binary.length);
    for (let i = 0; i < binary.length; i++) {
      bytes[i] = binary.charCodeAt(i);
    }
    const decoder = new TextDecoder();
    return decoder.decode(bytes);
  }

  const handleAddOrUpdateMinistryNotesSave = async event => {

    try {
      const url = `${process.env.REACT_APP_API_URL}/ministryNotes/${props.params.cell.ministryId}`;
      console.log("#75 url", url, talks, publications, notes)

      const notesEncoded = toBase64(notes);
     
      const body = {
        talks: talks,
        publications: publications,
        notes: notesEncoded
      };

      const headers = {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.jwt}`,
        },
        body: JSON.stringify(body),
      };

      await fetch(url, headers)
        .then(response => {
          if (response.status === 401) {
            window.location = '/sign-in';
          } else if (response.ok) {
            return response.json();
          } else if (response.status === 409) {
            console.error('#238 Duplicate entry. Respons', response);

            props.alert();
          }
        })
        .then(data => {
          // console.log("#124 data", data)
          //updateDom(cell, data.updatedId);
          // updateDom(cell, cell.id);
          // setBackdropOpen(false);
        })
        .catch(err => {
          console.error("#130 err", err)
          props.setError(t('Sorry! Failed to connect server =('));
        });
    } catch (error) {
      // console.error("#134 err", error)
      setError(error);
    }
  };

  // console.log("root.props2 ", props);
  return (
    <div>
      <ErrorDialog open={Boolean(error)} text={error} />
      <Dialog open={props.params.open}>
        <DialogTitle>{t('Notes')}</DialogTitle>
        <DialogContent>
          <DialogContentText>{t('MinistryNotes')}</DialogContentText>
          <Grid item xs={12}>
            <TextField
              autoFocus
              variant="outlined"
              margin="dense"
              id="talks"
              label={t('talks')}
              // type="number"
              type="text"
              inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
              onInput={e => {
                e.target.value = isNaN(parseInt(e.target.value))
                  ? 0
                  : Math.max(0, parseInt(e.target.value)).toString().slice(0, 2);
              }}
              value={talks}
              onChange={handleTalksChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              autoFocus
              variant="outlined"
              margin="dense"
              id="publications"
              label={t('publications')}
              type="text"
              inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
              onInput={e => {
                e.target.value = isNaN(parseInt(e.target.value))
                  ? 0
                  : Math.max(0, parseInt(e.target.value)).toString().slice(0, 2);
              }}
              value={publications}
              onChange={handlePublicationsChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextareaAutosize
              variant="contained"
              minRows={3}
              maxRows={15}
              aria-label={t('Notes')}
              placeholder={t('Notes')}
              // style={{ width: 200 }}
              style={{ width: '100%' }}
              // fullWidth
              value={notes}
              onChange={handleNotesChange}
            />
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              handleAddOrUpdateMinistryNotesSave();
              props.func({ open: false });
            }}
          >
            {t('Confirm')}
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => {
              props.func({ open: false });
            }}
          >
            {t('Close')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
